import 'react-toastify/dist/ReactToastify.css';

import React, { FunctionComponent, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { Form, Label, FormGroup } from 'reactstrap';
import { toast } from 'react-toastify';
import { Editor } from 'react-editor';
import { PageTitle } from '../Header/PageTitle';
import { list, ListResponse } from '../../api/ListAPI';
import { AppState } from '../../store/state';
import { ListState, requestAction, listGetSuccessAction, listPostSuccessAction, listFailureAction } from '../../store/list';

interface Props {
  list: ListState;
  reduxRequestAction: () => Promise<void>;
  reduxListGetSuccessAction: (finance: string) => Promise<void>;
  reduxListPostSuccessAction: () => Promise<void>;
  reduxListFailureAction: () => Promise<void>;
}

const ListPage: FunctionComponent<Props> = (props: Props) => {
  useEffect(() => {
    const getList = async () => {
      props.reduxRequestAction();

      //TODO
      const response = await list.getList('afi.csv');

      if (response.error) {
        toast(response.message);

        return props.reduxListFailureAction();
      }

      props.reduxListGetSuccessAction((response as ListResponse).data.list);
    }

    getList();
  }, [props]);
  
  const handleListChange = useCallback((event: string) => {
    props.reduxRequestAction();

    const postList = async () => { 
      const response = await list.postList(event);

      if (response.error) {
        toast(response.message);

        return props.reduxListFailureAction();
      }

      props.reduxListPostSuccessAction();
    }

    postList();
  }, [props]);

  const debounce = (func: Function, timeout: number) => {  
    let timer: NodeJS.Timeout;  
    
    return (...args: any) => {    
      clearTimeout(timer);    
      
      timer = setTimeout(() => { func(...args); }, timeout);  
    };
  }

  const handleListChangeWithDebounce = debounce(handleListChange, 2 * 1000);

  return (
    <>
      <PageTitle title="List" border={true} working={props.list.working}/>
      <div className="ml-2">
        <Form className="form">
          <FormGroup>
            {/*<Label className="pl-2" for="finance" hidden={!props.list.working}>Working...</Label>*/}
            <Editor value={props.list.list} onChange={handleListChangeWithDebounce}/>
          </FormGroup>
        </Form>
      </div>
    </>
  );
}

//redux store passes state to component
const mapStateToProps = (state: AppState) => {
  return { list: state.list };
};

//map dispatch to props
const mapDispatchToProps = (dispatch: any) => {
  return {
    reduxRequestAction: () => dispatch(requestAction()),
    reduxListGetSuccessAction: (finance: string) => dispatch(listGetSuccessAction(finance)),
    reduxListPostSuccessAction: () => dispatch(listPostSuccessAction()),
    reduxListFailureAction: () => dispatch(listFailureAction())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListPage);