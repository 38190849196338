import { BaseResponse, Base } from './Base';

export interface FinancesResponse extends BaseResponse {
  data: Finances;
}

export interface Finances {
  finances: {
    assets: string;
    expenses: string;
    incomes: string;
  }
}

export class FinancesAPI extends Base {
  getErrorResponse: BaseResponse = {
    error: true,
    message: 'Error requesting finances.'
  }

  postErrorResponse: BaseResponse = {
    error: true,
    message: 'Error saving finances.'
  }

  constructor() { super('/finances'); }
  
  async getFinances(): Promise<FinancesResponse | BaseResponse> {
    const response = await super.get(this.getErrorResponse);

    try {
      const responseObj = response as BaseResponse;

      if (!responseObj.data) { return responseObj; } 

      return responseObj as FinancesResponse;
    } catch (error) {
      return response;
    }
  }

  async postFinances(finances: Finances): Promise<BaseResponse> {
    const response = await super.post(JSON.stringify(finances), this.postErrorResponse);

    try {
      const responseObj = response as BaseResponse;

      return responseObj;
    } catch (error) {
      return response;
    }
  }
}

const finances = new FinancesAPI();

export { finances }