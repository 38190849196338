import { BaseResponse, Base } from './Base';

export interface ListResponse extends BaseResponse {
  data: {
    list: string;
  }
}

export class ListAPI extends Base {
  getErrorResponse: BaseResponse = {
    error: true,
    message: 'Error requesting list.'
  }

  postErrorResponse: BaseResponse = {
    error: true,
    message: 'Error saving list.'
  }

  constructor() { super('/list'); }
  
  async getList(listId: string): Promise<ListResponse | BaseResponse> {
    const response = await super.get(this.getErrorResponse, `/${listId}`);

    try {
      const responseObj = response as BaseResponse;

      if (!responseObj.data) { return responseObj; } 

      return responseObj as ListResponse;
    } catch (error) {
      return response;
    }
  }

  async postList(list: string): Promise<BaseResponse> {
    const response = await super.post(list, this.postErrorResponse);

    try {
      const responseObj = response as BaseResponse;

      return responseObj;
    } catch (error) {
      return response;
    }
  }
}

const list = new ListAPI();

export { list }