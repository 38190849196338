export const debounce = (func: Function, timeout: number) => {  
  let timer: NodeJS.Timeout;  
  
  return (...args: any) => {    
    clearTimeout(timer);    
    
    timer = setTimeout(() => { func(...args); }, timeout);  
  };
}

export const debounceDuration = 2 * 1000;