import { BaseCollection, CsvParser } from '../Models';

export class Assets extends BaseCollection<Asset> {
  private nameField: string = 'name';
  private worthField: string = 'worth';
  private willToField: string = 'willTo';
  idField = this.nameField;
  fields: Array<any> = [this.nameField, this.worthField, this.willToField];

  constructor(data: string) {
    super();

    this.collection = new CsvParser<Asset>().parseCsv(data, this.fields);
  }

  updateAssetName(thatObj: Asset, newData: string) {
    this.updateCollectionByField(thatObj, this.nameField, newData);
  }

  updateAssetWorth(thatObj: Asset, newData: string) {
    this.updateCollectionByField(thatObj, this.worthField, newData);
  }

  updateAssetWillTo(thatObj: Asset, newData: string) {
    this.updateCollectionByField(thatObj, this.willToField, newData);
  }
}

export interface Asset {
  name: string;
  worth: number; //TODO how?!
  willTo: string;
}

export class Expenses extends BaseCollection<Expense> {
  private nameField: string = 'name';
  private costField: string = 'cost';
  private periodField: string = 'period';
  idField = this.nameField;
  fields: Array<any> = [this.nameField, this.costField, this.periodField];

  constructor(data: string) {
    super();

    this.collection = new CsvParser<Expense>().parseCsv(data, this.fields);
  }

  updateExpenseName(thatObj: Expense, newData: string) {
    this.updateCollectionByField(thatObj, this.nameField, newData);
  }

  updateExpenseCost(thatObj: Expense, newData: string) {
    this.updateCollectionByField(thatObj, this.costField, newData);
  }

  updateExpensePeriod(thatObj: Expense, newData: string) {
    this.updateCollectionByField(thatObj, this.periodField, newData);
  }
}

export interface Expense {
  name: string;
  cost: number; //TODO how?!
  period: number; //TODO how?!
}

export class Incomes extends BaseCollection<Income> {
  private nameField: string = 'name';
  private amountField: string = 'amount';
  private periodField: string = 'period';
  idField = this.nameField;
  fields: Array<any> = [this.nameField, this.amountField, this.periodField];

  constructor(data: string) {
    super();

    this.collection = new CsvParser<Income>().parseCsv(data, this.fields);
  }

  updateIncomeName(thatObj: Income, newData: string) {
    this.updateCollectionByField(thatObj, this.nameField, newData);
  }

  updateIncomeAmount(thatObj: Income, newData: string) {
    this.updateCollectionByField(thatObj, this.amountField, newData);
  }

  updateIncomePeriod(thatObj: Income, newData: string) {
    this.updateCollectionByField(thatObj, this.periodField, newData);
  }
}

export interface Income {
  name: string;
  amount: number; //TODO how?!
  period: number; //TODO how?!
}

/*
export interface Bill {
  name: string;
  cost: number;
  //period: BillPeriod;
  //numberPeriodOccurrences: number;
}
*/

export enum Period {
  montly = 'monthly',
  bimonthly = 'bi-monthly',
  yearly = 'yearly',
  biyearly = 'bi-yearly',
  quartery = 'quarterly'
}

export interface FinancesState {
  working: boolean;
  finances: {
    assets: string,
    expenses: string,
    incomes: string
  }
}

const initialFinancesState: FinancesState = {
  working: false,
  finances: {
    assets: '',
    expenses: '',
    incomes: ''
  }
}

export const finances = (state: FinancesState = initialFinancesState, action: RequestAction | FinancesGetSuccessAction | FinancesPostSuccessAction | FinancesFailureAction): FinancesState => { 
  switch (action.type) {
    case REQUEST_ACTION: 
      return (function () {
        return { ...state, working: true }
      }());
    case FINANCES_GET_SUCCESS_ACTION: 
      return (function () {
        return { ...state, working: false, finances: action.payload }
      }());
    case FINANCES_POST_SUCCESS_ACTION: 
      return (function () {
        return { ...state, working: false }
      }());
    case FINANCES_FAILURE_ACTION: 
      return (function () {
        return { ...state, working: false }
      }());
    default:
      return state
  }
}

interface RequestAction { type: typeof REQUEST_ACTION }
const REQUEST_ACTION = 'REQUEST_ACTION';

interface FinancesGetSuccessAction { type: typeof FINANCES_GET_SUCCESS_ACTION, payload: { assets: string, expenses: string, incomes: string } }
const FINANCES_GET_SUCCESS_ACTION = 'FINANCES_GET_SUCCESS_ACTION';

interface FinancesPostSuccessAction { type: typeof FINANCES_POST_SUCCESS_ACTION }
const FINANCES_POST_SUCCESS_ACTION = 'FINANCES_POST_SUCCESS_ACTION';

interface FinancesFailureAction { type: typeof FINANCES_FAILURE_ACTION }
const FINANCES_FAILURE_ACTION = 'FINANCES_FAILURE_ACTION';

export const requestAction = (): RequestAction => {
  return { type: REQUEST_ACTION }
}

export const financesGetSuccessAction = (assets: string, expenses: string, incomes: string): FinancesGetSuccessAction => {
  return { type: FINANCES_GET_SUCCESS_ACTION, payload: { expenses, assets, incomes } }
}

export const financesPostSuccessAction = (): FinancesPostSuccessAction => {
  return { type: FINANCES_POST_SUCCESS_ACTION }
}

export const financesFailureAction = (): FinancesFailureAction => {
  return { type: FINANCES_FAILURE_ACTION }
}