import { BaseCollection, CsvParser } from '../Models';

export class Lists extends BaseCollection<List> {
  private nameField: string = 'name';
  private fileField: string = 'file';
  idField = this.nameField;
  fields: Array<any> = [this.nameField, this.fileField];

  constructor(data: string) {
    super();

    this.collection = new CsvParser<List>().parseCsv(data, this.fields);
  }

  addList(list: List) { this.add(list); }

  updateListName(list: List, name: string) {
    this.updateCollectionByField(list, this.nameField, name);
  }

  removeList(list: List) { this.remove(list); }
}

export interface List {
  name: string;
  file: string;
}

export interface ListsState {
  working: boolean;
  lists: string;
}

const initialListsState: ListsState = {
  working: false,
  lists: ''
}

export const lists = (state: ListsState = initialListsState, action: RequestAction | GetSuccessAction | PostSuccessAction | FailureAction | UpdateStateAction): ListsState => { 
  switch (action.type) {
    case REQUEST_ACTION: 
      return (function () {
        return { ...state, working: true }
      }());
    case GET_SUCCESS_ACTION: 
      return (function () {
        return { ...state, working: false, lists: action.payload }
      }());
    case POST_SUCCESS_ACTION: 
      return (function () {
        return { ...state, working: false }
      }());
    case FAILURE_ACTION: 
      return (function () {
        return { ...state, working: false }
      }());
    case UPDATE_STATE_ACTION: 
      return (function () {
        return { ...state, lists: action.payload.toCsvString() }
      }());
    default:
      return state
  }
}

interface RequestAction { type: typeof REQUEST_ACTION }
const REQUEST_ACTION = 'LISTS_REQUEST_ACTION';

interface GetSuccessAction { type: typeof GET_SUCCESS_ACTION, payload: string }
const GET_SUCCESS_ACTION = 'LISTS_GET_SUCCESS_ACTION';

interface PostSuccessAction { type: typeof POST_SUCCESS_ACTION }
const POST_SUCCESS_ACTION = 'LISTS_POST_SUCCESS_ACTION';

interface FailureAction { type: typeof FAILURE_ACTION }
const FAILURE_ACTION = 'LISTS_FAILURE_ACTION';

interface UpdateStateAction { type: typeof UPDATE_STATE_ACTION, payload: Lists }
const UPDATE_STATE_ACTION = 'LISTS_UPDATE_STATE_ACTION';

export const requestAction = (): RequestAction => {
  return { type: REQUEST_ACTION }
}

export const listsGetSuccessAction = (lists: string): GetSuccessAction => {
  return { type: GET_SUCCESS_ACTION, payload: lists }
}

export const listsPostSuccessAction = (): PostSuccessAction => {
  return { type: POST_SUCCESS_ACTION }
}

export const listsFailureAction = (): FailureAction => {
  return { type: FAILURE_ACTION }
}

export const updateStateAction = (lists: Lists): UpdateStateAction => {
  return { type: UPDATE_STATE_ACTION, payload: lists }
}