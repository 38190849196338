/* 
 * This file is responsible for managing base Life Assistant API interactions.
 * For AXIOS help: https://kapeli.com/cheat_sheets/Axios.docset/Contents/Resources/Documents/index
 */
import axios, { AxiosRequestConfig } from 'axios';

const baseURL = 'https://api.thelifeassistant.net/prod/v1';
const validateStatus = (status: number) => { return status >= 200 && status < 300; }

const catchFn = (error: any, errorResponse: BaseResponse) => {
  if (error.response) {
    //server responded with a status code not in 2xx range
    console.error(error.response.data);
    console.error(error.response.status);
    console.error(error.response.headers);
  } else if (error.request) {
    //no response was received
    console.error(error.request);
  } else {
    console.error('Error', error.message);
  }

  console.error(error.config);

  return errorResponse;
}

export interface BaseResponse {
  message?: string;
  error: boolean;
  data?: any
}

const authInfoKey = 'la-authinfo';

export class Base {
  private path?: string; 

  constructor(path?: string) { this.path = path; }

  private getAuthInfo(): AuthInfo {
    const authInfo = localStorage.getItem(authInfoKey);

    return JSON.parse(authInfo!);
  }

  public async post(data: any, errorResponse: BaseResponse, morePath?: string): Promise<any> {
    const config: AxiosRequestConfig = {
      baseURL: baseURL,
      url: `${this.path}${morePath || ''}`,
      headers: {},
      validateStatus: validateStatus
    }

    const authInfo = this.getAuthInfo();
    
    if (authInfo && authInfo.token) {
      config.headers = { Authorization: `Bearer ${authInfo.token}` }
    }

    if (process.env.NODE_ENV.toString() === 'local') {
      config.baseURL = 'http://localhost:3000';
    } 

    console.log(config);

    const postRequestWrapper = async () => {
      return this.makeRequest(this.postRequest, [data, config, errorResponse], errorResponse);
    }

    return postRequestWrapper();
  }

  private makeRequest = async (fn: any, args: Array<any>, errorResponse: BaseResponse) => {
    try {
      const response = await fn.apply(this, args);
    
      if (!response) {
        console.error('no request promise');
  
        return errorResponse;
      } else {
        return response;
      }
    } catch (error) {
      console.error(error);
        
      return errorResponse;
    }
  }

  private async postRequest(data: any, config: AxiosRequestConfig, errorResponse: BaseResponse): Promise<any> {
    return axios
      .post(`${config.baseURL}${config.url}`, data, config).then(response => {
        console.log(response);
        
        return response.data;
      })
      .catch(error => { return catchFn(error, errorResponse); });
  }

  public async get(errorResponse: BaseResponse, morePath?: string): Promise<any> {
    const config = {
      baseURL: baseURL,
      url: `${this.path}${morePath || ''}`,
      headers: {},
      validateStatus: (status: number) => { return status >= 200 && status < 300; }
    }

    const authInfo = this.getAuthInfo();

    if (authInfo && authInfo.token) {
      config.headers = { Authorization: `Bearer ${authInfo.token}` }
    }

    if (process.env.NODE_ENV.toString() === 'local') {
      config.baseURL = 'http://localhost:3000';
    }

    console.log(config);

    const getRequestWrapper = async () => {
      return this.makeRequest(this.getRequest, [config, errorResponse], errorResponse);
    }

    return getRequestWrapper();
  }

  private async getRequest(config: AxiosRequestConfig, errorResponse: BaseResponse): Promise<any> {
    return axios
      .get(`${config.baseURL}${config.url}`, config).then(response => {
        console.log(response);
        
        return response.data;
      })
      .catch(error => { return catchFn(error, errorResponse); });
  }
}

export interface AuthInfo {
  issued: string;
  refreshToken: string;
  token: string;
}