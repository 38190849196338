import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { AiOutlineLoading } from 'react-icons/ai';
import { Button } from 'reactstrap';
import { AppState } from '../../store/state';

interface Props {
  state?: AppState;
  title: string
  border?: boolean;
  working: boolean;
  button?: boolean;
  buttonText?: string;
  buttonOnClick?: Function;
}

export const PageTitle: FunctionComponent<Props> = (props: Props) => {
  return (
    <>
      <div className="PageTitle">
        <h5 className="ml-3 pt-2">{props.title}<span>{props.button && <Button size="sm" className="ml-2" onClick={() => { if (props.buttonOnClick) { console.log(props.state); props.buttonOnClick(props.state); } }}>{props.buttonText}</Button>}{props.working && <img src="loading.gif"/>}</span></h5>
        {props.border &&
        <hr />
        }
      </div>
    </>
  );
}


//redux store passes state to component
const mapStateToProps = (state: AppState) => {
  return { state: state };
};

export default connect(mapStateToProps)(PageTitle);