export interface LoginState {
  waitingForSecretCodeValidation: boolean;
  failCount: number;
}

const initialLoginState: LoginState = {
  waitingForSecretCodeValidation: false,
  failCount: 0
}

export const login = (state: LoginState = initialLoginState, action: AwaitSecretCodeValidationAction | LoginSuccessAction | LoginFailureAction): LoginState => {
  switch (action.type) {
    case AWAIT_SECRET_CODE_VALIDATION_ACTION:
      return (function () {
        return { waitingForSecretCodeValidation: true, failCount: 0 }
      }());
    case LOGIN_SUCCESS_ACTION: 
      return (function () {
        return { waitingForSecretCodeValidation: false, failCount: 0 }
      }());
    case LOGIN_FAILURE_ACTION: 
      return (function () {
        return { waitingForSecretCodeValidation: true, failCount: state.failCount + 1 }
      }());
    default:
      return state
  }
}

interface AwaitSecretCodeValidationAction { type: typeof AWAIT_SECRET_CODE_VALIDATION_ACTION }
const AWAIT_SECRET_CODE_VALIDATION_ACTION = 'AWAIT_SECRET_CODE_VALIDATION_ACTION';

interface LoginSuccessAction { type: typeof LOGIN_SUCCESS_ACTION }
const LOGIN_SUCCESS_ACTION = 'LOGIN_SUCCESS_ACTION';

interface LoginFailureAction { type: typeof LOGIN_FAILURE_ACTION }
const LOGIN_FAILURE_ACTION = 'LOGIN_FAILURE_ACTION';

export const awaitSecretCodeValidationAction = (): AwaitSecretCodeValidationAction => {
  return { type: AWAIT_SECRET_CODE_VALIDATION_ACTION }
}

export const loginSuccessAction = (): LoginSuccessAction => {
  return { type: LOGIN_SUCCESS_ACTION }
}

export const loginFailureAction = (): LoginFailureAction => {
  return { type: LOGIN_FAILURE_ACTION }
}