import React, { FunctionComponent, useState } from 'react';
import { connect } from 'react-redux';
import { Link, Route, Switch, HashRouter } from 'react-router-dom';
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink } from 'reactstrap';
import { RiLogoutCircleRLine } from 'react-icons/ri';
import { AppState } from '../../store/state';
import { auth } from '../../api/AuthAPI';
import ProtectedRoute from './ProtectedRoute';
import { DashboardPage } from '../../components/DashboardPage/DashboardPage';
import { ChasingDaylightPage } from '../../components/ChasingDaylightPage/ChasingDaylightPage';
import NotesPage from '../../components/NotesPage/NotesPage';
import FinancePage from '../../components/FinancePage/FinancePage';
import ContactsPage from '../../components/ContactsPage/ContactsPage';
import ListsPage from '../../components/ListsPage/ListsPage';
import ListPage from '../../components/ListPage/ListPage';
import LoginPage from '../../components/LoginPage/LoginPage';
import { PageNotFound } from '../../components/PageNotFound/PageNotFound';

const Header: FunctionComponent = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  const signOut = () => auth.signOut();

  return (
    <>
      <HashRouter>
        <Navbar color="light" light expand="md" fixed="fixed">
          <NavbarBrand tag={Link} className="ha-no-link" to="/">
            The Life Assistant
          </NavbarBrand>
          {auth.userHasSession() &&
            <NavbarToggler onClick={toggle}/>
          }
          <Collapse isOpen={isOpen} navbar>
            <Nav className="mr-auto" navbar>
              <NavItem>
                <NavLink tag={Link} to="/chasingdaylight">Chasing Daylight</NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} to="/notes">Notes</NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} to="/finances">Finances</NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} to="/contacts">Contacts</NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} to="/lists">Lists</NavLink>
              </NavItem>
            </Nav>
            {auth.userHasSession() &&
            <NavLink tag={Link} to="/logout">
              <RiLogoutCircleRLine onClick={signOut} />
            </NavLink>
            }
          </Collapse>
        </Navbar>
        <Switch>
          <ProtectedRoute exact path="/" component={DashboardPage}/>
          <Route path="/login" component={LoginPage}/>
          <ProtectedRoute path="/chasingdaylight" component={ChasingDaylightPage}/>
          <ProtectedRoute path="/notes" component={NotesPage}/>
          <ProtectedRoute path="/finances" component={FinancePage}/>
          <ProtectedRoute path="/contacts" component={ContactsPage}/>
          <ProtectedRoute path="/lists" component={ListsPage}/>
          <ProtectedRoute path="/list/:listId" component={ListPage}/>
          <ProtectedRoute path="/404" component={PageNotFound}/>
        </Switch>
      </HashRouter>
    </>
  );
}

//redux store passes state to component
const mapStateToProps = (state: AppState) => {
  return state;
};

//map dispatch to props
const mapDispatchToProps = (dispatch: any) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);

