import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import App from './App';
import { auth } from './api/AuthAPI';
import { AUTH_REFRESH_FAILED } from './Strings';

//TODO SSR flag
//ReactDOM.hydrate(<App/>, document.getElementById('root'));
auth.configure().then(() => {
  ReactDOM.render(<><App/></>, document.getElementById('root'));

  const timer = setInterval(() => {
    try {
      auth.tryRefreshSession();
    } catch (error) {
      console.error(AUTH_REFRESH_FAILED, error);
    }
  }, 5 * 1000); //60 * 59 * 1000
   
  window.addEventListener('beforeunload', (event) => {
    event.preventDefault();

    clearInterval(timer);

    event.returnValue = '';
  });
});

//TODO not offline
serviceWorker.unregister();
