import { ThunkAction } from 'redux-thunk';
import { ContactsAPI, ContactsResponse, ContactsList, Contact } from '../api/ContactsAPI';
import { AppState } from '../store/state';

export interface ContactsState {
  working: boolean;
  contacts: ContactsList;
  error: boolean;
  errorMessage: string;
}

export interface ErrorMessage { //TODO base response type
  error: boolean;
  errorMessage: string;
}

const initialContactsState: ContactsState = {
  working: false,
  contacts: new ContactsList(''),
  error: false,
  errorMessage: ''
}

export const contacts = (
  state: ContactsState = initialContactsState,
  action: RequestAction | GetSuccessAction | PostSuccessAction | FailureAction): ContactsState => { 
  switch (action.type) {
    case REQUEST_ACTION: 
      return (function () {
        return { ...state, working: true }
      }());
    case GET_SUCCESS_ACTION: 
      return (function () {
        return { ...state, working: false, contacts: action.payload }
      }());
    case POST_SUCCESS_ACTION: 
      return (function () {
        return { ...state, working: false, contacts: action.payload }
      }());
    case FAILURE_ACTION: 
      return (function () {
        return { ...state, working: false }
      }());
    default:
      return state
  }
}

interface RequestAction { type: typeof REQUEST_ACTION }
const REQUEST_ACTION = 'CONTACTS_REQUEST_ACTION';

interface GetSuccessAction { type: typeof GET_SUCCESS_ACTION, payload: ContactsList }
const GET_SUCCESS_ACTION = 'CONTACTS_GET_SUCCESS_ACTION';

interface PostSuccessAction { type: typeof POST_SUCCESS_ACTION, payload: ContactsList }
const POST_SUCCESS_ACTION = 'CONTACTS_POST_SUCCESS_ACTION';

interface FailureAction { type: typeof FAILURE_ACTION, payload: ErrorMessage }
const FAILURE_ACTION = 'CONTACTS_FAILURE_ACTION';

type Actions = RequestAction | GetSuccessAction | PostSuccessAction | FailureAction;

export const requestAction = (): RequestAction => {
  return { type: REQUEST_ACTION }
}

export const contactsGetSuccessAction = (contactsList: ContactsList): GetSuccessAction => {
  return { type: GET_SUCCESS_ACTION, payload: contactsList }
}

export const contactsPostSuccessAction = (contactsList: ContactsList): PostSuccessAction => {
  return { type: POST_SUCCESS_ACTION, payload: contactsList }
}

export const contactsFailureAction = (errorMessage: string): FailureAction => {
  return { type: FAILURE_ACTION, payload: { error: true, errorMessage } }
}

export const getContacts = (): ThunkAction<Promise<void>, {}, AppState, Actions> => {
  return async (dispatch) => {
    dispatch(requestAction());

    const response = await new ContactsAPI().getContacts();

    if (response.error) {
      dispatch(contactsFailureAction(response.message || ''));

      return;
    }

    const rawContactsList = (response as ContactsResponse).data.contacts;

    dispatch(contactsGetSuccessAction(new ContactsList(rawContactsList)));
  }
}

const postContacts = (contactsList: ContactsList): ThunkAction<Promise<void>, {}, AppState, Actions> => {
  return async (dispatch) => {
    dispatch(requestAction());

    const response = await new ContactsAPI().postContacts(contactsList);

    if (response.error) {
      dispatch(contactsFailureAction(response.message || ''));

      return;
    }

    dispatch(contactsPostSuccessAction(contactsList));
  }
}

export const addContact = (contactsList: ContactsList): ThunkAction<Promise<void>, {}, AppState, Actions> => {
  return async (dispatch) => {
    const id = new Date().getTime().toString();

    contactsList.addContact({ name: `new contact ${id}`, number: '', email: '' });

    return dispatch(postContacts(contactsList));
  };
}

export const updateContact = (contactsList: ContactsList, contact: Contact, field: string, newData: string): ThunkAction<Promise<void>, {}, AppState, Actions> => {
  return async (dispatch) => {
    contactsList.updateCollectionByField(contact, field, newData);
    
    return dispatch(postContacts(contactsList));
  };
}

export const removeContact = (contactsList: ContactsList, contact: Contact): ThunkAction<Promise<void>, {}, AppState, Actions> => {
  return async (dispatch) => {
    contactsList.removeContact(contact);
    
    return dispatch(postContacts(contactsList));
  };
}