export interface AFITop100Film {
  watched: boolean;
  title: number;
}

export interface Bookmark {
  data: string;
}

export class BaseCollection<T> {
  collection!: Array<T>;
  fields!: Array<string>;
  idField!: string;

  add(obj: T) { this.collection.unshift(obj); }

  updateCollectionByField(thatObj: T, field: string, newData: string) {
    const obj = _findById(this.collection, this.idField, thatObj);

    if (obj) {
      const typelessObj: any = obj;

      const objIndex = _findIndex(this.collection, this.idField, thatObj);

      //update object field
      typelessObj[field] = newData;

      //update object in collection
      if (objIndex) { this.collection[objIndex] = typelessObj as T; }
    }
  }

  remove(thatObj: T) {
    const obj = _findById(this.collection, this.idField, thatObj);

    if (obj) {
      const objIndex = _findIndex(this.collection, this.idField, thatObj);

      //remove object from collection
      if (objIndex) { this.collection.splice(objIndex, 1); }
    }
  }

  toCsvString() {
    return this.collection.map(obj => {
      let line = '';

      const typlessThisObj: any = obj;

      this.fields.forEach(field => {
        if (typlessThisObj[field]) {
          line += typlessThisObj[field] + ',';
        } else {
          line += ',';
        }
      });

      if (line[line.length - 1] === ',') {
        line = line.substring(0, line.length - 1);
      }

      return line;
    }).join(',\n');
  }
}

const _findById = (collection: Array<any>, idField: string, thatObj: any) => {
  const typelessThatObj: any = thatObj;

  return collection.find(thisObj => {
    const typlessThisObj: any = thisObj;
    
    return typlessThisObj[idField] === typelessThatObj[idField];
  });
}

const _findIndex = (collection: Array<any>, idField: string, thatObj: any) => {
  const typelessThatObj: any = thatObj;

  return collection.findIndex(thisObj => {
    const typlessThisObj: any = thisObj;

    return typlessThisObj[idField] === typelessThatObj[idField];
  });
}

export class CsvParser<T> {
  parseCsv(data: string, fields: Array<string>): Array<T> {
    const lines = data.split('\n');

    let collection: Array<T> = [];

    lines.forEach((line) => {
      const records = line.split(',');
  
      const data: any = {};

      let i = 0;

      fields.forEach((field) => {
        if (!records[i]) {
          i++;

          return data[field] = '';
        }

        let record = records[i];

        if (this.isEmpty(record)) {
          i++;

          return data[field] = '';
        }

        record = this.stripQuotes(record);

        data[field] = record;

        i++;
      });

      collection.push(data);
    });

    return collection;
  }

  isEmpty(record: string): boolean {
    if (record !== '') { return false; }

    return true;
  }

  stripQuotes(record: string): string {
    record = this.stripFirstQuote(record);
    record = this.stripLastQuote(record);

    return record;
  }

  stripFirstQuote(record: string): string {
    if (record.charAt(0) === '"') {
      record = record.substring(1, record.length - 1);
    }

    return record;
  }

  stripLastQuote(record: string): string {
    if (record.charAt(0) === '"') {
      record = record.substring(1, record.length - 1);
    }

    return record;
  }
}

/*
const checkedLineToRecord = (line: string) => {
  const record = line.split(',');

  let model;

  //historically checked is "x"
  if (record[0] === 'x') { 
    model = {
      checked: true,
      title: line.replace('x,', '')
    };
  } else {
    model = {
      checked: false,
      title: line.replace(',', '')
    };
  }

  return model;
}
*/