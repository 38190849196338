import 'react-toastify/dist/ReactToastify.css';

import React, { FunctionComponent, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Table, Button, ButtonGroup } from 'reactstrap';
import { toast } from 'react-toastify';
import { Editor } from 'react-editor';
import { PageTitle } from '../Header/PageTitle';
import { lists, ListsResponse } from '../../api/ListsAPI';
import { AppState } from '../../store/state';
import { Lists, List, ListsState, updateStateAction, requestAction, listsGetSuccessAction, listsPostSuccessAction, listsFailureAction } from '../../store/lists';
import { debounce, debounceDuration } from '../../Utils';

interface Props {
  lists: ListsState;
  reduxRequestAction: () => Promise<void>;
  reduxListsGetSuccessAction: (lists: string) => Promise<void>;
  reduxListsPostSuccessAction: () => Promise<void>;
  reduxListsFailureAction: () => Promise<void>;
  reduxUpdateStateAction: (lists: Lists) => Promise<void>;
}

const ListsPage: FunctionComponent<Props> = (props: Props) => {
  useEffect(() => {
    const getLists = async () => {
      props.reduxRequestAction();

      const response = await lists.getLists();

      if (response.error) {
        toast(response.message);

        return props.reduxListsFailureAction();
      }

      props.reduxListsGetSuccessAction((response as ListsResponse).data.lists);
    }

    getLists();
  }, []);
  
  const handleListNameChange = useCallback((listsList: Lists, list: List, name: string) => {
    listsList.updateListName(list, name);

    postLists(listsList);
  }, []);

  const postLists = async (listsList: Lists) => { 
    props.reduxUpdateStateAction(listsList);
    props.reduxRequestAction();
    
    const response = await lists.postLists(listsList);

    if (response.error) {
      toast(response.message);

      return props.reduxListsFailureAction();
    }

    props.reduxListsPostSuccessAction();
  }

  const handleListNameChangeWithDebounce = debounce(handleListNameChange, debounceDuration);

  const addList = () => {
    const listsList = new Lists(props.lists.lists);
   
    const id = new Date().getTime().toString();

    listsList.addList({ name: `new list ${id}`, file: `${id}.csv` });
 
    postLists(listsList);
  }

  const removeList = (list: List) => {
    const listsList = new Lists(props.lists.lists);
    listsList.removeList(list);

    postLists(listsList);
  }

  const renderTableBody = () => {
    const listsList = new Lists(props.lists.lists);

    return listsList.collection.map((list: List, key: any) => {
      return (
        <tr key={key}>
          <td><Editor value={list.name} onChange={(name: string) => { handleListNameChangeWithDebounce(listsList, list, name); }} /></td>
          <td>
            <span>
              <Button outline color="primary" size="sm" disabled={props.lists.working}><a href="/list">Edit</a></Button>
              <Button outline size="sm" disabled={props.lists.working} className="ml-2" onClick={() => { removeList(list); }}>Delete</Button>
            </span>
          </td>
        </tr>
      );
    });
  }

  return (
    <>
      <PageTitle title="Lists" border={false} button={true} buttonText="Add" working={props.lists.working} buttonOnClick={addList}/>
      {props.lists && props.lists.lists &&
      <Table responsive>
        <thead>
          <tr>
            <th>Name</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {renderTableBody()}
        </tbody>
      </Table>
      }
    </>
  );
}

//redux store passes state to component
const mapStateToProps = (state: AppState) => {
  return { lists: state.lists };
};

//map dispatch to props
const mapDispatchToProps = (dispatch: any) => {
  return {
    reduxRequestAction: () => dispatch(requestAction()),
    reduxListsGetSuccessAction: (lists: string) => dispatch(listsGetSuccessAction(lists)),
    reduxListsPostSuccessAction: () => dispatch(listsPostSuccessAction()),
    reduxListsFailureAction: () => dispatch(listsFailureAction()),
    reduxUpdateStateAction: (lists: Lists) => dispatch(updateStateAction(lists))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListsPage);