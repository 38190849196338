import 'react-toastify/dist/ReactToastify.css';

import React, { FunctionComponent, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { Form, Label, FormGroup } from 'reactstrap';
import { toast } from 'react-toastify';
import { Editor } from 'react-editor';
import { PageTitle } from '../Header/PageTitle';
import { notes, NotesResponse } from '../../api/NotesAPI';
import { AppState } from '../../store/state';
import { NotesState, requestAction, notesGetSuccessAction, notesPostSuccessAction, notesFailureAction } from '../../store/notes';
import { debounce, debounceDuration } from '../../Utils';

interface Props {
  notes: NotesState;
  reduxRequestAction: () => Promise<void>;
  reduxNotesGetSuccessAction: (notes: string) => Promise<void>;
  reduxNotesPostSuccessAction: () => Promise<void>;
  reduxNotesFailureAction: () => Promise<void>;
}

const NotesPage: FunctionComponent<Props> = (props: Props) => {
  useEffect(() => {
    const getNotes = async () => {
      props.reduxRequestAction();

      const response = await notes.getNotes();

      if (response.error) {
        toast(response.message);

        return props.reduxNotesFailureAction();
      }

      props.reduxNotesGetSuccessAction((response as NotesResponse).data.notes);
    }

    getNotes();

    //TODO setInterval to poll for changes
  }, []);
  
  const handleNotesChange = useCallback((event: string) => {
    props.reduxRequestAction();

    const postNotes = async () => { 
      const response = await notes.postNotes(event);

      if (response.error) {
        toast(response.message);

        return props.reduxNotesFailureAction();
      }

      props.reduxNotesPostSuccessAction();
    }

    postNotes();
  }, []);

  const handleNotesChangeWithDebounce = debounce(handleNotesChange, debounceDuration);

  return (
    <>
      <PageTitle title="Notes" border={true} working={props.notes.working}/>
      <div className="ml-2">
        <Form className="form">
          <FormGroup>
            {/*<Label className="pl-2" for="notes" hidden={!props.notes.working}>Working...</Label>*/}
            <Editor value={props.notes.notes} onChange={handleNotesChangeWithDebounce}/>
          </FormGroup>
        </Form>
      </div>
    </>
  );
}

//redux store passes state to component
const mapStateToProps = (state: AppState) => {
  return { notes: state.notes };
};

//map dispatch to props
const mapDispatchToProps = (dispatch: any) => {
  return {
    reduxRequestAction: () => dispatch(requestAction()),
    reduxNotesGetSuccessAction: (notes: string) => dispatch(notesGetSuccessAction(notes)),
    reduxNotesPostSuccessAction: () => dispatch(notesPostSuccessAction()),
    reduxNotesFailureAction: () => dispatch(notesFailureAction())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NotesPage);