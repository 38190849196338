import 'react-toastify/dist/ReactToastify.css';

import React, { FunctionComponent, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { Table, Button } from 'reactstrap';
import { toast } from 'react-toastify';
import { Editor } from 'react-editor';
import { PageTitle } from '../Header/PageTitle';
import { finances, FinancesResponse } from '../../api/FinancesAPI';
import { AppState } from '../../store/state';
import { Assets, Asset, Expenses, Expense, Incomes, Income, FinancesState, requestAction, financesGetSuccessAction, financesPostSuccessAction, financesFailureAction } from '../../store/finances';
import { debounce, debounceDuration } from '../../Utils';

interface Props {
  finances: FinancesState;
  reduxRequestAction: () => Promise<void>;
  reduxFinancesGetSuccessAction: (assets: string, expenses: string, incomes: string) => Promise<void>;
  reduxFinancesPostSuccessAction: () => Promise<void>;
  reduxFinancesFailureAction: () => Promise<void>;
}

const FinancePage: FunctionComponent<Props> = (props: Props) => {
  useEffect(() => {
    const getFinances = async () => {
      props.reduxRequestAction();

      const response = await finances.getFinances();

      if (response.error) {
        toast(response.message);

        return props.reduxFinancesFailureAction();
      }

      const financesData = (response as FinancesResponse).data;

      props.reduxFinancesGetSuccessAction(financesData.finances.assets, financesData.finances.expenses, financesData.finances.incomes);
    }

    getFinances();
  }, []);

  const handleAssetNameChange = useCallback((assetsList: Assets, asset: Asset, newData: string) => {
    assetsList.updateAssetName(asset, newData);

    postFinances(assetsList.toCsvString());
  }, []);

  const handleAssetWorthChange = useCallback((assetsList: Assets, asset: Asset, newData: string) => {
    assetsList.updateAssetWorth(asset, newData);

    postFinances(assetsList.toCsvString());
  }, []);

  const handleAssetWillToChange = useCallback((assetsList: Assets, asset: Asset, newData: string) => {
    assetsList.updateAssetWillTo(asset, newData);

    postFinances(assetsList.toCsvString());
  }, []);
  
  const handleExpenseNameChange = useCallback((expensesList: Expenses, expense: Expense, newData: string) => {
    expensesList.updateExpenseName(expense, newData);

    postFinances(undefined, expensesList.toCsvString());
  }, []);

  const handleExpenseCostChange = useCallback((expensesList: Expenses, expense: Expense, newData: string) => {
    expensesList.updateExpenseCost(expense, newData);

    postFinances(undefined, expensesList.toCsvString());
  }, []);

  const handleExpensePeriodChange = useCallback((expensesList: Expenses, expense: Expense, newData: string) => {
    expensesList.updateExpensePeriod(expense, newData);

    postFinances(undefined, expensesList.toCsvString());
  }, []);

  const handleIncomeNameChange = useCallback((incomesList: Incomes, income: Income, newData: string) => {
    incomesList.updateIncomeName(income, newData);

    postFinances(undefined, undefined, incomesList.toCsvString());
  }, []);

  const handleIncomeAmountChange = useCallback((incomesList: Incomes, income: Income, newData: string) => {
    incomesList.updateIncomeAmount(income, newData);

    postFinances(undefined, undefined, incomesList.toCsvString());
  }, []);

  const handleIncomePeriodChange = useCallback((incomesList: Incomes, income: Income, newData: string) => {
    incomesList.updateIncomePeriod(income, newData);

    postFinances(undefined, undefined, incomesList.toCsvString());
  }, []);

  const postFinances = async (assets?: string, expenses?: string, incomes?: string) => { 
    props.reduxRequestAction();

    const data: any = { finances: {} };
    
    if (assets) {
      data.finances.assets = assets;
    } else {
      data.finances.assets = props.finances.finances.assets;
    }

    if (expenses) {
      data.finances.expenses = expenses;
    } else {
      data.finances.expenses = props.finances.finances.expenses;
    }

    if (incomes) {
      data.finances.incomes = incomes;
    } else {
      data.finances.incomes = props.finances.finances.incomes;
    }

    console.log(data);

    return;
    
    const response = await finances.postFinances(data);

    if (response.error) {
      toast(response.message);

      return props.reduxFinancesFailureAction();
    }

    props.reduxFinancesPostSuccessAction();
  }

  //const handleFinancesChangeWithDebounce = debounce(handleFinancesChange, 2 * 1000);

  const handleAssetNameChangeWithDebounce = debounce(handleAssetNameChange, debounceDuration);
  const handleAssetWorthChangeWithDebounce = debounce(handleAssetWorthChange, debounceDuration);
  const handleAssetWillToChangeWithDebounce = debounce(handleAssetWillToChange, debounceDuration);

  const handleIncomeNameChangeWithDebounce = debounce(handleIncomeNameChange, debounceDuration);
  const handleIncomeAmountChangeWithDebounce = debounce(handleIncomeAmountChange, debounceDuration);
  const handleIncomePeriodChangeWithDebounce = debounce(handleIncomePeriodChange, debounceDuration);

  const handleExpenseNameChangeWithDebounce = debounce(handleExpenseNameChange, debounceDuration);
  const handleExpenseCostChangeWithDebounce = debounce(handleExpenseCostChange, debounceDuration);
  const handleExpensePeriodChangeWithDebounce = debounce(handleExpensePeriodChange, debounceDuration);

  const renderExpensesTableBody = () => {
    const expensesList = new Expenses(props.finances.finances.expenses);

    return expensesList.collection.map((expense: Expense, key: any) => {
      return (
        <tr key={key}>
          <td><Editor value={expense.name} onChange={(newData: string) => { handleExpenseNameChangeWithDebounce(expensesList, expense, newData); } }/></td>
          <td><Editor value={expense.cost} onChange={(newData: string) => { handleExpenseCostChangeWithDebounce(expensesList, expense, newData); } }/></td>
          <td><Editor value={expense.period} onChange={(newData: string) => { handleExpensePeriodChangeWithDebounce(expensesList, expense, newData); } }/></td>
          <td><Button size="sm" block disabled={props.finances.working}>Delete</Button></td>
        </tr>
      );
    });
  }

  const renderIncomesTableBody = () => {
    const incomesList = new Incomes(props.finances.finances.incomes);

    return incomesList.collection.map((income: Income, key: any) => {
      return (
        <tr key={key}>
          <td><Editor value={income.name} onChange={(newData: string) => { handleIncomeNameChangeWithDebounce(incomesList, income, newData); } }/></td>
          <td><Editor value={income.amount} onChange={(newData: string) => { handleIncomeAmountChangeWithDebounce(incomesList, income, newData); } }/></td>
          <td><Editor value={income.period} onChange={(newData: string) => { handleIncomePeriodChangeWithDebounce(incomesList, income, newData); } }/></td>
          <td><Button size="sm" block disabled={props.finances.working}>Delete</Button></td>
        </tr>
      );
    });
  }

  const renderAssetsTableBody = () => {
    const assetsList = new Assets(props.finances.finances.assets);

    return assetsList.collection.map((asset: Asset, key: any) => {
      return (
        <tr key={key}>
          <td><Editor value={asset.name} onChange={(newData: string) => { handleAssetNameChangeWithDebounce(assetsList, asset, newData); } }/></td>
          <td><Editor value={asset.worth} onChange={(newData: string) => { handleAssetWorthChangeWithDebounce(assetsList, asset, newData); } }/></td>
          <td><Editor value={asset.willTo} onChange={(newData: string) => { handleAssetWillToChangeWithDebounce(assetsList, asset, newData); } }/></td>
          <td><Button size="sm" block disabled={props.finances.working}>Delete</Button></td>
        </tr>
      );
    });
  }

  return (
    <>
      <PageTitle title="Finances" border={false} working={props.finances.working} />
      <h3>Expenses</h3>
      {props.finances && props.finances.finances && props.finances.finances.expenses &&
      <Table responsive>
        <thead>
          <tr>
            <th>Name</th>
            <th>Cost</th>
            <th>Period</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {renderExpensesTableBody()}
        </tbody>
      </Table>
      }
      <h3>Incomes</h3>
      {props.finances && props.finances.finances && props.finances.finances.incomes &&
      <Table responsive>
        <thead>
          <tr>
            <th>Name</th>
            <th>Amount</th>
            <th>Period</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {renderIncomesTableBody()}
        </tbody>
      </Table>
      }
      <h3>Assets</h3>
      {props.finances && props.finances.finances && props.finances.finances.assets &&
      <Table responsive>
        <thead>
          <tr>
            <th>Name</th>
            <th>Worth</th>
            <th>Will To</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {renderAssetsTableBody()}
        </tbody>
      </Table>
      }
    </>
  );
}

//redux store passes state to component
const mapStateToProps = (state: AppState) => {
  return { finances: state.finances };
};

//map dispatch to props
const mapDispatchToProps = (dispatch: any) => {
  return {
    reduxRequestAction: () => dispatch(requestAction()),
    reduxFinancesGetSuccessAction: (assets: string, expenses: string, incomes: string) => dispatch(financesGetSuccessAction(assets, expenses, incomes)),
    reduxFinancesPostSuccessAction: () => dispatch(financesPostSuccessAction()),
    reduxFinancesFailureAction: () => dispatch(financesFailureAction())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FinancePage);