import { BaseResponse, Base } from './Base';
import { BaseCollection, CsvParser } from '../Models';

export class ContactsAPI extends Base {
  getErrorResponse: BaseResponse = {
    error: true,
    message: 'Error requesting contacts.'
  }

  postErrorResponse: BaseResponse = {
    error: true,
    message: 'Error saving contacts.'
  }

  constructor() { super('/contacts'); }
  
  async getContacts(): Promise<ContactsResponse | BaseResponse> {
    const response = await super.get(this.getErrorResponse);

    try {
      const responseObj = response as BaseResponse;

      if (!responseObj.data) { return responseObj; } 

      return responseObj as ContactsResponse;
    } catch (error) {
      return response;
    }
  }

  async postContacts(contactsList: ContactsList): Promise<BaseResponse> {
    console.log('post!\n\n', contactsList.toCsvString().toLowerCase());
    
    const response = await super.post(contactsList.toCsvString().toLowerCase(), this.postErrorResponse);

    try {
      const responseObj = response as BaseResponse;

      return responseObj;
    } catch (error) {
      return response;
    }
  }
}

export interface ContactsResponse extends BaseResponse {
  data: { contacts: string; }
}

export class ContactsList extends BaseCollection<Contact> {
  nameField: string = 'name';
  numberField: string = 'number';
  emailField: string = 'email';
  idField = this.nameField;
  fields: Array<any> = [this.nameField, this.numberField, this.emailField];

  constructor(data: string) {
    super();

    this.collection = new CsvParser<Contact>().parseCsv(data, this.fields);
  }

  addContact(contact: Contact) { this.add(contact); }

  removeContact(contact: Contact) { this.remove(contact); }
}

export interface Contact {
  name: string;
  number?: string;
  email?: string;
}

//const contactsAPI = new ContactsAPI();

//export { contactsAPI }