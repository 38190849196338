import 'react-toastify/dist/ReactToastify.css';

import React, { FormEvent, FunctionComponent, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Form, Label, FormText, FormFeedback, FormGroup, Input } from 'reactstrap';
import { toast } from 'react-toastify';
import { PageTitle } from '../Header/PageTitle';
import { auth } from '../../api/AuthAPI';
import { AppState } from '../../store/state';
import { LoginState, awaitSecretCodeValidationAction, loginSuccessAction, loginFailureAction } from '../../store/login';

interface Props {
  login: LoginState;
  reduxAwaitSecretCodeValidationAction: () => Promise<void>;
  reduxLoginSuccessAction: () => Promise<void>;
  reduxLoginFailureAction: () => Promise<void>;
}

const LoginPage: FunctionComponent<Props> = (props: Props) => {
  const [state, setState] = useState({ email: '', secretCode: '', working: false });

  const [valid, setValidity] = useState({ email: false, secretCode: false });

  const submitLoginForm = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (state.working) { return console.warn('already doing work'); }

    setState({ ...state, working: true });

    const response = await auth.requestCustomChallenge(state.email);

    setState({ ...state, working: false });

    if (response.error) {
      toast(response.message);

      return props.reduxLoginFailureAction();
    } 
    
    props.reduxAwaitSecretCodeValidationAction();
  }

  const submitSecretCodeForm = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (state.working) { return console.warn('already doing work'); }

    //TODO handle fail count of 3 nicer
    //props.login.failCount

    setState({ ...state, working: true });

    const response = await auth.answerCustomChallenge(state.secretCode);

    setState({ ...state, working: false });

    if (!response.data.authenticated) {
      toast(response.message);

      return props.reduxLoginFailureAction();
    } 

    //TODO set somewhere
    //await auth.getUser();

    props.reduxLoginSuccessAction();

    window.location.href = '/#/';
  }

  const handleEmailChange = (event: React.ChangeEvent<any>) => {
    setState({ ...state, email: event.currentTarget.value });

    validateEmail();
  }

  const validateEmail = () => {
    const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    setValidity({ ...valid, email: emailRex.test(state.email) });
  }

  const handleSecretCodeChange = (event: React.ChangeEvent<any>) => {
    setState({ ...state, secretCode: event.currentTarget.value });

    validateSecretCode();
  }

  const validateSecretCode = () => {
    setValidity({ ...valid, secretCode: (state.secretCode && state.secretCode === '' ? false : true) });
  }

  return (
    <>
      <PageTitle title="Login" border={true} working={state.working}/>
      <div className="ml-2">
        {props.login && !props.login.waitingForSecretCodeValidation &&
        <Form className="form" onSubmit={(event) => { submitLoginForm(event) }}>
          <FormGroup>
            <Label for="email">Email:</Label>
            <Input
              type="email"
              name="email"
              title="email"
              id="email"
              placeholder="E-mail"
              value={state.email}
              onChange={handleEmailChange}
              onPaste={handleEmailChange}
              invalid={!valid.email}
              valid={valid.email}
              required
            />
            <FormText>E-mail is required.</FormText>
            <FormFeedback invalid={!valid.email ? 1 : 0}>
              Please provide a valid e-mail for login.
            </FormFeedback>
          </FormGroup>
          <Button size="lg" block disabled={state.working}>Login</Button>
          <br/>
        </Form>
        }
        {props.login && props.login.waitingForSecretCodeValidation &&
        <Form className="form" onSubmit={(event) => { submitSecretCodeForm(event) }}>
          <FormGroup>
            <Label for="email">Secret Code:</Label>
            <Input
              type="text"
              name="secretCode"
              title="secretCode"
              id="secretCode"
              placeholder="Secret Code"
              value={state.secretCode}
              onChange={handleSecretCodeChange}
              onPaste={handleSecretCodeChange}
              invalid={!valid.secretCode}
              valid={valid.secretCode}
              required
            />
            <FormText>Secret code is required.</FormText>
            <FormFeedback invalid={!valid.secretCode ? 1 : 0}>
              Please enter the secret code sent to your e-mail for authorization.
            </FormFeedback>
          </FormGroup>
          <Button size="lg" block disabled={state.working}>Submit Secret Code</Button>
          <br/>
        </Form>
        }
      </div>
    </>
  );
}

//redux store passes state to component
const mapStateToProps = (state: AppState) => {
  return {
    login: state.login
  };
};

//map dispatch to props
const mapDispatchToProps = (dispatch: any) => {
  return {
    reduxAwaitSecretCodeValidationAction: () => dispatch(awaitSecretCodeValidationAction()),
    reduxLoginSuccessAction: () => dispatch(loginSuccessAction()),
    reduxLoginFailureAction: () => dispatch(loginFailureAction())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
