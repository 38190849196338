import { BaseResponse, Base } from './Base';

const configResponseKey = 'la-configresponse';

export interface ConfigResponse extends BaseResponse {
  data: {
    recordingsBucket: string;
    dataBucket: string;
    userPoolId: string;
    userPoolWebsiteClientId: string;
  }
}

export class ConfigAPI extends Base {
  getErrorResponse: BaseResponse = {
    error: true,
    message: 'Error requesting config.'
  }

  constructor() { super('/config'); }
  
  public async getRuntimeConfig(useCache: boolean = true): Promise<ConfigResponse | BaseResponse> {
    if (useCache) { return this.getCachedRuntimeConfig(); }

    const response = await super.get(this.getErrorResponse);

    try {
      const responseObj = response as BaseResponse;

      if (!responseObj.data) { return responseObj; } 

      const configResponse = responseObj as ConfigResponse;

      localStorage.setItem(configResponseKey, JSON.stringify(configResponse));

      return configResponse;
    } catch (error) {
      return response;
    }
  }

  private getCachedRuntimeConfig(): ConfigResponse | BaseResponse {
    const storedConfigResponse = localStorage.getItem(configResponseKey);
    if (storedConfigResponse) { return JSON.parse(storedConfigResponse) as ConfigResponse; }
    
    return this.getErrorResponse;
  }
}

const config = new ConfigAPI();

export { config }