import React, { FunctionComponent } from 'react';
import Iframe from 'react-iframe';

export const ChasingDaylightPowerCompanyTab: FunctionComponent = ()  => {
  return (
    <>
      <Iframe url="https://cloudwatch.amazonaws.com/dashboard.html?dashboard=Chasing-Daylight-Power-Company&context=eyJSIjoidXMtZWFzdC0xIiwiRCI6ImN3LWRiLTEwNDM3MjcwNDU1MCIsIlUiOiJ1cy1lYXN0LTFfWWdlQk9KbW1pIiwiQyI6ImpxZWtkYzRnMjViNzM3MWIycmN0M2Y4dHQiLCJJIjoidXMtZWFzdC0xOjEzOGFiN2ZhLWIxNmUtNGFkYy1hOGIwLTZiMWRiZDAzMWUzOSIsIk0iOiJQdWJsaWMifQ=="
        width="100%"
        height="100%"
        frameBorder={0}
        position="relative"
        allowFullScreen
      />
    </>
  );
}