export interface NotesState {
  working: boolean;
  notes: string;
}

const initialNotesState: NotesState = {
  working: false,
  notes: ''
}

export const notes = (state: NotesState = initialNotesState, action: RequestAction | NotesGetSuccessAction | NotesPostSuccessAction | NotesFailureAction): NotesState => { 
  switch (action.type) {
    case REQUEST_ACTION: 
      return (function () {
        return { ...state, working: true }
      }());
    case NOTES_GET_SUCCESS_ACTION: 
      return (function () {
        return { ...state, working: false, notes: action.payload }
      }());
    case NOTES_POST_SUCCESS_ACTION: 
      return (function () {
        return { ...state, working: false }
      }());
    case NOTES_FAILURE_ACTION: 
      return (function () {
        return { ...state, working: false }
      }());
    default:
      return state
  }
}

interface RequestAction { type: typeof REQUEST_ACTION }
const REQUEST_ACTION = 'REQUEST_ACTION';

interface NotesGetSuccessAction { type: typeof NOTES_GET_SUCCESS_ACTION, payload: string }
const NOTES_GET_SUCCESS_ACTION = 'NOTES_GET_SUCCESS_ACTION';

interface NotesPostSuccessAction { type: typeof NOTES_POST_SUCCESS_ACTION }
const NOTES_POST_SUCCESS_ACTION = 'NOTES_POST_SUCCESS_ACTION';

interface NotesFailureAction { type: typeof NOTES_FAILURE_ACTION }
const NOTES_FAILURE_ACTION = 'NOTES_FAILURE_ACTION';

export const requestAction = (): RequestAction => {
  return { type: REQUEST_ACTION }
}

export const notesGetSuccessAction = (notes: string): NotesGetSuccessAction => {
  return { type: NOTES_GET_SUCCESS_ACTION, payload: notes }
}

export const notesPostSuccessAction = (): NotesPostSuccessAction => {
  return { type: NOTES_POST_SUCCESS_ACTION }
}

export const notesFailureAction = (): NotesFailureAction => {
  return { type: NOTES_FAILURE_ACTION }
}