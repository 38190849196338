import { BaseResponse, Base } from './Base';
import { Lists } from '../store/lists';

export interface ListsResponse extends BaseResponse {
  data: {
    lists: string;
  }
}

export class ListsAPI extends Base {
  getErrorResponse: BaseResponse = {
    error: true,
    message: 'Error requesting lists.'
  }

  postErrorResponse: BaseResponse = {
    error: true,
    message: 'Error saving lists.'
  }

  constructor() { super('/lists'); }
  
  async getLists(): Promise<ListsResponse | BaseResponse> {
    const response = await super.get(this.getErrorResponse);

    try {
      const responseObj = response as BaseResponse;

      if (!responseObj.data) { return responseObj; } 

      return responseObj as ListsResponse;
    } catch (error) {
      return response;
    }
  }

  async postLists(lists: Lists): Promise<BaseResponse> {
    const response = await super.post(lists.toCsvString().toLowerCase(), this.postErrorResponse);

    try {
      const responseObj = response as BaseResponse;

      return responseObj;
    } catch (error) {
      return response;
    }
  }
}

const lists = new ListsAPI();

export { lists }