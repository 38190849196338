import { Amplify, Auth as AmplifyAuth } from 'aws-amplify';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { config, ConfigResponse } from './ConfigAPI';
import { BaseResponse, Base, AuthInfo } from './Base';

const region = 'us-west-2';
const authInfoKey = 'la-authinfo';

export default class AuthAPI extends Base {
  authInfo!: AuthInfo | null;
  private cognitoUser!: CognitoUser & { challengeParam: { email: string } } | null;

  async configure() {
    const response = await config.getRuntimeConfig(false);
    
    if (response.error) { 
      console.error(response.error);

      throw new Error('Error getting config.'); //TODO handle
    }
    
    const data = (response as ConfigResponse).data;

    Amplify.configure({
      aws_cognito_region: region, //TODO region from config
      aws_user_pools_id: data.userPoolId,
      aws_user_pools_web_client_id: data.userPoolWebsiteClientId,
      aws_mandatory_sign_in: 'enable'
    });
  }

  userHasSession() {
    const authInfo = localStorage.getItem(authInfoKey);

    if (authInfo) {
      this.authInfo = JSON.parse(authInfo);

      return this.validSession(); //TODO cleanup user info
    }
   
    return false;
  }

  private validSession(): boolean {
    const currentEpoch = Math.round(new Date().getTime() / 1000);
    const issuedEpoch = parseInt(this.authInfo?.issued!);

    if ((currentEpoch - issuedEpoch) >= 1800) { return false; } 

    return true;
  }

  async requestCustomChallenge(email: string): Promise<BaseResponse> {
    try {
      this.cognitoUser = await AmplifyAuth.signIn(email);

      return { error: false, data: this.cognitoUser };
    } catch (error) {
      console.error(error);

      return { message: error.message, error: true };
    }
  }

  async answerCustomChallenge(secretCode: string): Promise<BaseResponse> {
    try {
      await AmplifyAuth.sendCustomChallengeAnswer(this.cognitoUser, secretCode);

      const setSession = await this.trySetSessionInfo();

      if (setSession) {
        return { error: false, data: { authenticated: true } };
      } else {
        return { message: 'Invalid secret code.', error: true, data: { authenticated: false } };
      }
    } catch (error) {
      console.error(error);

      return { message: error.message, error: true, data: { authenticated: false } };
    }
  }

  private async trySetSessionInfo(): Promise<boolean> {
    try {
      const currentSession = await AmplifyAuth.currentSession();

      if (!currentSession.isValid()) { return false; }

      this.setAuthInfo(currentSession.getRefreshToken().getToken(), currentSession.getIdToken().getJwtToken());

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  private setAuthInfo(refreshToken: string, token: string) {
    this.authInfo = {
      issued: Math.round(new Date().getTime() / 1000).toString(),
      refreshToken: refreshToken,
      token: token
    };

    localStorage.setItem(authInfoKey, JSON.stringify(this.authInfo));
  }

  async tryRefreshSession() {
    if (this.validSession()) { return; }

    console.log('will refresh session');

    const response = await config.getRuntimeConfig();
    const data = (response as ConfigResponse).data;

    new Promise((resolve, reject) => {
      fetch(`https://cognito-idp.${region}.amazonaws.com/`, {
        headers: {
          'X-Amz-Target': 'AWSCognitoIdentityProviderService.InitiateAuth',
          'Content-Type': 'application/x-amz-json-1.1',
        },
        mode: 'cors',
        cache: 'no-cache',
        method: 'POST',
        body: JSON.stringify({
          ClientId: data.userPoolWebsiteClientId,
          AuthFlow: 'REFRESH_TOKEN_AUTH',
          AuthParameters: {
            REFRESH_TOKEN: this.authInfo?.refreshToken
            //SECRET_HASH: 'your_secret', // In case you have configured client secret
          }
        }),
      }).then((response) => {
        response.json().then(data => {
          if (!data.AuthenticationResult) {
            return reject(data);
          }

          console.log(data);

          this.setAuthInfo(this.authInfo?.refreshToken!, data.AuthenticationResult.IdToken);

          resolve();
        });
      }).catch(error => { reject(error); });
    })
  }

  async signOut(): Promise<BaseResponse> {
    try {
      await AmplifyAuth.signOut();

      localStorage.removeItem(authInfoKey);
      
      this.cognitoUser = null;
      this.authInfo = null;

      window.location.href = '/#/login';
      window.location.reload(true);
    } catch (error) {
      console.error(error);

      return { message: error.message, error: true };
    }

    return { error: false, data: this.cognitoUser };
  }
}

const auth = new AuthAPI();

export { auth }