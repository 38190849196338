import { BaseResponse, Base } from './Base';

export interface NotesResponse extends BaseResponse {
  data: {
    notes: string;
  }
}

export class NotesAPI extends Base {
  getErrorResponse: BaseResponse = {
    error: true,
    message: 'Error requesting notes.'
  }

  postErrorResponse: BaseResponse = {
    error: true,
    message: 'Error saving notes.'
  }

  constructor() { super('/notes'); }
  
  async getNotes(): Promise<NotesResponse | BaseResponse> {
    const response = await super.get(this.getErrorResponse);

    try {
      const responseObj = response as BaseResponse;

      if (!responseObj.data) { return responseObj; } 

      return responseObj as NotesResponse;
    } catch (error) {
      return response;
    }
  }

  async postNotes(notes: string): Promise<BaseResponse> {
    const response = await super.post(notes, this.postErrorResponse);

    try {
      const responseObj = response as BaseResponse;

      return responseObj;
    } catch (error) {
      return response;
    }
  }
}

const notes = new NotesAPI();

export { notes }