export interface ListState {
  working: boolean;
  list: string;
}

const initialListState: ListState = {
  working: false,
  list: ''
}

export const list = (state: ListState = initialListState, action: RequestAction | ListGetSuccessAction | ListPostSuccessAction | ListFailureAction): ListState => { 
  switch (action.type) {
    case REQUEST_ACTION: 
      return (function () {
        return { ...state, working: true }
      }());
    case LIST_GET_SUCCESS_ACTION: 
      return (function () {
        return { ...state, working: false, list: action.payload }
      }());
    case LIST_POST_SUCCESS_ACTION: 
      return (function () {
        return { ...state, working: false }
      }());
    case LIST_FAILURE_ACTION: 
      return (function () {
        return { ...state, working: false }
      }());
    default:
      return state
  }
}

interface RequestAction { type: typeof REQUEST_ACTION }
const REQUEST_ACTION = 'REQUEST_ACTION';

interface ListGetSuccessAction { type: typeof LIST_GET_SUCCESS_ACTION, payload: string }
const LIST_GET_SUCCESS_ACTION = 'LIST_GET_SUCCESS_ACTION';

interface ListPostSuccessAction { type: typeof LIST_POST_SUCCESS_ACTION }
const LIST_POST_SUCCESS_ACTION = 'LIST_POST_SUCCESS_ACTION';

interface ListFailureAction { type: typeof LIST_FAILURE_ACTION }
const LIST_FAILURE_ACTION = 'LIST_FAILURE_ACTION';

export const requestAction = (): RequestAction => {
  return { type: REQUEST_ACTION }
}

export const listGetSuccessAction = (list: string): ListGetSuccessAction => {
  return { type: LIST_GET_SUCCESS_ACTION, payload: list }
}

export const listPostSuccessAction = (): ListPostSuccessAction => {
  return { type: LIST_POST_SUCCESS_ACTION }
}

export const listFailureAction = (): ListFailureAction => {
  return { type: LIST_FAILURE_ACTION }
}