import React, { FunctionComponent, useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import { PageTitle } from '../Header/PageTitle';
import { ChasingDaylightPowerCompanyTab } from '../ChasingDaylightPowerCompanyTab/ChasingDaylightPowerCompanyTab';
import { ChasingDaylightLiveStreamTab } from '../ChasingDaylightLiveStreamTab/ChasingDaylightLiveStreamTab';
import { ChasingDaylightRecordingsTab } from '../ChasingDaylightRecordingsTab/ChasingDaylightRecordingsTab';

export const ChasingDaylightPage: FunctionComponent = () => {
  const [activeTab, setActiveTab] = useState('1');

  return (
    <>
      <PageTitle title="Chasing Daylight" border={true} working={false}/>
      <Row>
        <Col>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={(activeTab === '1' ? 'active' : '')}
                onClick={() => { setActiveTab('1'); }}
              >
                Power Company
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={(activeTab === '2' ? 'active' : '')}
                onClick={() => { setActiveTab('2'); }}
              >
                Live Stream
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={(activeTab === '3' ? 'active' : '')}
                onClick={() => { setActiveTab('3'); }}
              >
                Recordings
              </NavLink>
            </NavItem>
          </Nav>
          <br/>
          <TabContent activeTab={activeTab} style={{ height: "700px" }}>
            <TabPane tabId="1" className="h-100">
              <ChasingDaylightPowerCompanyTab/>
            </TabPane>
            <TabPane tabId="2">
              <ChasingDaylightLiveStreamTab/>
            </TabPane>
            <TabPane tabId="3">
              <ChasingDaylightRecordingsTab/>
            </TabPane>
          </TabContent>
        </Col>
      </Row>
    </>
  );
}