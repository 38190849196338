import { Reducer, combineReducers, createStore, applyMiddleware, compose } from 'redux';
import * as thunk from 'redux-thunk';
import { logger } from 'redux-logger';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { AppState } from './state';
import { login } from './login';
import { notes } from './notes';
import { finances } from './finances';
import { contacts } from './contacts';
import { lists } from './lists';
import { list } from './list';

const rootReducer: Reducer<AppState> = combineReducers<AppState>({
  login: login,
  notes: notes,
  finances: finances,
  contacts: contacts,
  lists: lists,
  list: list
});

const persistedReducer = persistReducer({
  key: 'root',
  storage,
  blacklist: ['notes', 'login', 'notes', 'finances', 'contacts', 'list'] //TODO we can use this for offline work
}, rootReducer);

const store = createStore(persistedReducer, compose(applyMiddleware(thunk.default, logger)));
const persistor = persistStore(store);

export default { store, persistor }