import React, { FunctionComponent } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import store from './store';
import { Container } from 'reactstrap';
import { ToastContainer} from 'react-toastify';
import Header from './components/Header/Header';

const App: FunctionComponent = () => {
  return (
    <>
      <Provider store={store.store}>
        <PersistGate loading={null} persistor={store.persistor}>
          <Container fluid className="h-100">
            <ToastContainer/>
            <Header/>
          </Container>
        </PersistGate>
      </Provider>
    </>
  );
};

export default App;