import 'react-toastify/dist/ReactToastify.css';
import React, { FunctionComponent, useEffect } from 'react';
import { connect } from 'react-redux';
import { Table, Button } from 'reactstrap';
import { toast } from 'react-toastify';
import { Editor } from 'react-editor';
import { PageTitle } from '../Header/PageTitle';
import { ContactsList, Contact } from '../../api/ContactsAPI';
import { AppState } from '../../store/state';
import {
  ContactsState,
  getContacts,
  addContact,
  updateContact,
  removeContact
} from '../../store/contacts';
import { debounce, debounceDuration } from '../../Utils';

interface Props {
  contactsState: ContactsState;
  state: AppState;
  getContactsAction: () => Promise<void>;
  addContactAction: (contactsList: ContactsList) => Promise<void>;
  updateContactAction: (contactsList: ContactsList, contact: Contact, field: string, newData: string) => Promise<void>;
  removeContactAction: (contactsList: ContactsList, contact: Contact) => Promise<void>;
}

const ContactsPage: FunctionComponent<Props> = (props: Props) => {
  useEffect(() => { props.getContactsAction(); }, []);
  
  if (props.contactsState.error) { toast(props.contactsState.errorMessage); }
  
  const handleContactNameChange = (contactsList: ContactsList, contact: Contact, newData: string) => {
    props.updateContactAction(contactsList, contact, 'name', newData);
  }

  const handleContactNumberChange = (contactsList: ContactsList, contact: Contact, newData: string) => {;
    props.updateContactAction(contactsList, contact, 'number', newData);
  }

  const handleContactEmailChange = (contactsList: ContactsList, contact: Contact, newData: string) => {
    props.updateContactAction(contactsList, contact, 'email', newData);
  }

  const handleContactNameChangeWithDebounce = debounce(handleContactNameChange, debounceDuration);
  const handleContactNumberChangeWithDebounce = debounce(handleContactNumberChange, debounceDuration);
  const handleContactEmailChangeWithDebounce = debounce(handleContactEmailChange, debounceDuration);

  const renderTableBody = () => {
    const contactsList = new ContactsList(props.contactsState.contacts.toCsvString());

    return props.contactsState.contacts.collection.map((contact: Contact, key: any) => {
      return (
        <tr key={key}>
          <td><Editor value={contact.name} onChange={(name: string) => { handleContactNameChangeWithDebounce(contactsList, contact, name); } }/></td>
          <td><Editor value={contact.number} onChange={(number: string) => { handleContactNumberChangeWithDebounce(contactsList, contact, number); } }/></td>
          <td><Editor value={contact.email} onChange={(email: string) => { handleContactEmailChangeWithDebounce(contactsList, contact, email); } }/></td>
          <td><Button outline size="sm" disabled={props.contactsState.working} className="ml-2" onClick={() => { props.removeContactAction(contactsList, contact); }}>Delete</Button></td>
        </tr>
      );
    });
  }

  return (
    <>
      <PageTitle state={props.state} title="Contacts" border={false} button={true} buttonText="Add" working={props.contactsState.working} buttonOnClick={(state: AppState) => { props.addContactAction(state.contacts.contacts); }}/>
      {props.contactsState.contacts && props.contactsState.contacts.collection &&
      <Table responsive> 
        <thead>
          <tr>
            <th>Name</th>
            <th>Number</th>
            <th>Email</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {renderTableBody()}
        </tbody>
      </Table>
      }
    </>
  );
}

//redux store passes state to component
const mapStateToProps = (state: AppState) => {
  return { contactsState: state.contacts, state };
};

//map dispatch to props
const mapDispatchToProps = (dispatch: any) => {
  return {
    getContactsAction: () => dispatch(getContacts()),
    addContactAction: (contactsList: ContactsList) => dispatch(addContact(contactsList)),
    updateContactAction: (contactsList: ContactsList, contact: Contact, field: string, newData: string) => dispatch(updateContact(contactsList, contact, field, newData)),
    removeContactAction: (contactsList: ContactsList, contact: Contact) => dispatch(removeContact(contactsList, contact))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactsPage);