import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { auth } from '../../api/AuthAPI';

const ProtectedRoute = ({ component: Component, path }: RouteProps) => {
  if (!auth.userHasSession()) {
    return <Redirect to="/login"/>;
  } 

  return <Route component={Component} path={path}/>;
};

export default ProtectedRoute;